import React, { useContext, useEffect, useRef, useState } from 'react'
import { requestContext } from '../../../context/requestContext'
import { generateRandomColorPaletteWithBrightness, groupBy } from '../../../services/arrayHandless'
import ListServiceConstructionPlan from '../../../components/table/serviceConstructionPlan'
import { namesScreens } from '../../../constants'
import { formContext } from '../../../context/formContext'
import { ChartPie, LineChart } from '../../../components/charts'
import { criarDatasetCompletado, geneateDataChartPizzaPorCenExec, generateItemsCalculedAcPr } from '../../../services/constructionPlan'
import { formateDate, parseFromMysqlDateToJSDate } from '../../../services/dateHandless'
import FormContructions from '../../../components/form/constructionPlan'
import { LOGO } from '../../../config/flavor'
import { stylesReport } from '../../../css/globlalStyle'
import { useReactToPrint } from 'react-to-print'
import { useNavigate } from 'react-router-dom'
import ListMidias from '../../../components/list/midias'
import { handleSuccess, handleWaring } from '../../../services/isValid'
const ReportConstructionPlan = () => {
	const { sisf_sinc_int_amp_id, pl_ob_cod, conc_nome } = useContext(formContext)
	const { setInfoRequest, getItemOversightPhotos, service, monitoring_progress, monitoring_progress_work } = useContext(requestContext)
	const [services, setServices] = useState([])
	const [chartPizzaPorCenExec, setChartPizzaPorCenExec] = useState(null)
	const [chartLineAdvances, setChartLineAdvances] = useState(null)
	const [photos, setPhotos] = useState([])
	const componentRef = useRef(null)
	const navigate = useNavigate()
	const generateReport = () => {
		setInfoRequest(handleWaring('Aguarde o relatório ser gerado'))
		setTimeout(() => {
			handlePrint()
		}, 6000)
		setInfoRequest(handleSuccess('Relatório gerado com sucesso'))
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: 'Relatório de Plano de Obra',
		copyStyles: true,
		onAfterPrint: () => onAfterPrint(),
	})
	const onAfterPrint = () => {
		navigate(namesScreens.CONSTRUCTION_PLANVIEW)
	}
	useEffect(() => {
		const controllItems = () => {
			if (service?.data) {
				const { data } = service
				let dataWithCodigo = []
				dataWithCodigo = data.map(item => ({
					...item,
					gp_codigo: parseInt(String(item.serv_sub_cat_subitem_der).substring(4, 6))
				}))
				const pl_ob_reais_prev = data.reduce((previuesValues, currentValues) => previuesValues + (currentValues.pvs_qtd * currentValues.pvs_valor), 0)
				const sumCalcAcProg = generateItemsCalculedAcPr({
					dataWithCodigo,
					pl_ob_reais_prev
				})
				const group = groupBy(sumCalcAcProg, 'gp_codigo')
				setServices(group)
			}
		}
		controllItems()
	}, [service])
	useEffect(() => {
		const controllItems = () => {
			if (Array.isArray(service?.data)) {
				const { data } = service
				const chartData = geneateDataChartPizzaPorCenExec({
					data, state: { sisf_sinc_int_amp_id, pl_ob_cod, conc_nome }
				})
				setChartPizzaPorCenExec(chartData)
			}
		}
		controllItems()
	}, [service])
	useEffect(() => {
		const controllItems = () => {
			try {
				if (Array.isArray(monitoring_progress?.data) && Array.isArray(monitoring_progress_work?.data)) {
					const { data: tempAvancoExecutado } = monitoring_progress_work
					const { data: avancoPrevisto } = monitoring_progress
					const groupByData = groupBy(tempAvancoExecutado, 'apo_data')
					const avancoExecutado = []
					Object.keys(groupByData).forEach(item => {
						if (Array.isArray(groupByData[item])) {
							const lass = groupByData[item].sort((a, b) => new Date(a.apo_prog_ob_por_cen_progress) - new Date(b.apo_prog_ob_por_cen_progress))[groupByData[item].length - 1]
							avancoExecutado.push({
								...lass
							})
						}
					})
					const todasAsDatas = [
						...new Set([...avancoPrevisto.map(item => item.acob_date_prev_fim)]),
						...avancoExecutado.map(item => item.apo_data)
					].slice(1).sort((a, b) => new Date(a) - new Date(b))

					const avancoExecutadoCompletado = criarDatasetCompletado(avancoExecutado, todasAsDatas, 'apo_prog_ob_por_cen_progress', 'apo_data')
					const avancoPrevistoCompletado = criarDatasetCompletado(avancoPrevisto, todasAsDatas, 'acob_perc_prev', 'acob_date_prev_fim')
					const colors = generateRandomColorPaletteWithBrightness(2)
					const data = {
						labels: todasAsDatas.map(data => formateDate(data)),
						datasets: [
							{
								label: 'Avanço Executado',
								data: [...avancoExecutadoCompletado],
								borderColor: colors[0].color,
								backgroundColor: colors[0].brightness,
								fill: false,
								tension: 0.1
							},
							{
								label: 'Avanço Previsto',
								data: avancoPrevistoCompletado,
								borderColor: colors[1].color,
								backgroundColor: colors[1].brightness,
								fill: false,
								tension: 0.1
							}
						]
					}
					setChartLineAdvances(data)
				}
			} catch (error) {
				console.log(error)
			}
		}
		controllItems()
	}, [monitoring_progress, monitoring_progress_work])
	useEffect(() => {
		const fetchPhotosMovies = async () => {
			if (Array.isArray(service?.data)) {
				const { data } = service
				const pvsCods = data.map(item => item.prog_serv_serv_cod)
				const respone = await getItemOversightPhotos(pvsCods)
				if (respone?.data?.length) {
					const { data } = respone
					const newItems = data.map(item => {
						const created_at = parseFromMysqlDateToJSDate(item.created_at)
						return {
							...item,
							timestamp: new Date(created_at).getTime()
						}
					})
					setPhotos(newItems)
				}
			}
		}
		fetchPhotosMovies()
	}, [])
	return (
		<div className='content'>
			<div className='card mt-3 mb-3 sticky-top'>
				<div className='card-body'>
					<button className='btn btn-danger' onClick={generateReport}>
						Imprimir ou Gerar PDF <i className="fa fa-file-pdf" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div className='container-fluid' ref={componentRef} size={'A4'}>
				<div style={stylesReport.header}>
					<img src={LOGO} alt="image_logo" style={stylesReport.headerImage} />
					<div style={stylesReport.headerText}>
						<p style={stylesReport.smallHeaderText}>Relatório de Plano de Obra</p>
						<p style={stylesReport.smallHeaderText}>Gerado no dia {formateDate()}</p>
					</div>
				</div>
				<div className='card mb-3'>
					<div className='card-body'>
						<FormContructions />
					</div>
				</div>
				<div className='card mb-3'>
					<div className='card-header'>
						<div className='card-title'>
							Serviços
						</div>
					</div>
					<div className='card-body'>
						{services ? Object.keys(services).sort((a, b) => a - b).map(item => (
							<ListServiceConstructionPlan key={item} items={services[item]} />
						)) : ''}
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-body">
						<div className="card-header">
							<h5 className="card-title">Percentuais de Execução</h5>
						</div>
						{chartPizzaPorCenExec && (
							<ChartPie data={chartPizzaPorCenExec} />
						)}
					</div>
				</div>
				<div className="card mb-3">
					<div className="card-header">
						<h5 className="card-title">Progresso Previsto e Executado</h5>
					</div>
					<div className="card-body">
						{chartLineAdvances && (
							<LineChart data={chartLineAdvances} />
						)}
					</div>
				</div>
				<div className='card'>
					<div className="card-header">
						<h5 className="card-title">Fotos da Obra</h5>
					</div>
					<div className='card-body'>
						{photos.length ? (
							<ListMidias items={photos} />
						) : ''}
					</div>
				</div>
			</div>
		</div>
	)
}
export default ReportConstructionPlan